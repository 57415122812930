import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import createRequests from '../../common/services/create-requests';
import createStore from '../../common/services/create-store';
import { waitForActionMiddleware } from '../../common/store/wait-for-action/wait-for-action-middleware';
import { SET_MAIN_POST } from '../store/main-post';
import { reducers } from '../store/reducers';

export function createReduxStore({
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  isSSR,
  language,
  bundleName,
  flowAPI,
}) {
  const p = {};
  const getStore = () => p.store;

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
    }),
    waitForActionMiddleware([SET_MAIN_POST]),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
  ];

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
