import { combineReducers } from 'redux';
import { wixDataReducer } from '@wix/communities-blog-client-common';
import commonReducers from '../../common/reducers';
import categories from '../../common/store/categories/categories-reducer';
import postCount from '../../common/store/post-count/post-count-reducer';
import relatedPosts from '../../post-page/reducers/related-posts';
import recentPostsIds from '../../recent-posts/reducers/recent-posts-ids';
import { mainPostReducer } from './main-post';

export const reducers = combineReducers({
  ...commonReducers,
  wixData: wixDataReducer,
  postCount,
  categories,
  mainPost: mainPostReducer,
  recentPostsIds,
  relatedPosts,
});

export type AppState = ReturnType<typeof reducers>;
