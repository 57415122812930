import { isEqual } from 'lodash';
import { Dispatch, Store } from 'redux';
import { RELATED_POSTS_SETTINGS_PARAMS } from '@wix/communities-blog-client-common';
import setPosts from '../../common/actions/set-posts';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getRecentPosts } from '../../common/selectors/recent-posts-selectors';
import { getRelatedPosts } from '../../common/selectors/related-posts-selectors';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import fetchRecentPosts from '../../post-page/actions/fetch-recent-posts';
import fetchRelatedPosts from '../../post-page/actions/fetch-related-posts';
import { getMainPost } from '../store/main-post';
import { AppState } from '../store/reducers';

export function fetchWidgetPosts() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const mainPost = getMainPost(getState());
    if (!mainPost) {
      dispatch(setPosts([]));
      dispatch(setPostCount(0));
      return;
    }

    const postCount = getAppSettingsValue({
      state: getState() as any,
      key: RELATED_POSTS_SETTINGS_PARAMS.postCount.appSettingsPath,
      fallback: RELATED_POSTS_SETTINGS_PARAMS.postCount.defaultValue,
    });
    const hasRelatedPosts = mainPost.relatedPostIds.length > 0;

    if (hasRelatedPosts) {
      await dispatch(fetchRelatedPosts(mainPost, postCount));
      const relatedPosts = getRelatedPosts(getState(), mainPost.id);
      dispatch(setPosts(relatedPosts));
      dispatch(setPostCount(relatedPosts.length));
    } else {
      await dispatch(fetchRecentPosts(mainPost, postCount));
      const recentPosts = getRecentPosts(getState(), mainPost.id);
      dispatch(setPosts(recentPosts));
      dispatch(setPostCount(recentPosts.length));
    }
  };
}

export function listenToMainPostChanges(store: Store) {
  const buildState = () => {
    return {
      mainPostId: getMainPost(store.getState())?.id,
      postCount: getAppSettingsValue({
        state: store.getState(),
        key: RELATED_POSTS_SETTINGS_PARAMS.postCount.appSettingsPath,
        fallback: RELATED_POSTS_SETTINGS_PARAMS.postCount.defaultValue,
      }),
    };
  };

  let prevState = buildState();

  store.subscribe(async () => {
    const currentState = buildState();
    if (currentState.mainPostId && !isEqual(prevState, currentState)) {
      prevState = currentState;
      await store.dispatch(fetchWidgetPosts());
    }
  });
}
